<template>
  <div class="reward-page py-4 mt-4">
    <div class="container">
      <div class="card-container">
        <div class="w-full main-card px-3 md:px-4 lg:px-5 py-4 lg:py-5 xl:py-7">
        <Tabs size='large' v-model:activeKey="activeKey"  class="w-full">
          <TabPane key="1" tab="Engagement and Recommendations">
            <BarChart></BarChart>
          </TabPane>
          <TabPane key="2" id='metricsTab' tab="Commute Metrics" >
            <EmployerMetrics />
            <div class="mt-16"></div>
            <MembersMetrics />
            <DashStatsQuery />
            <QueryAlert :query="query" />
          </TabPane>
        </Tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DashStatsQuery from '@/services/queries/dash/DashStatsQuery.vue';
import BarChart from '@/components/dashboard/charts/BarChart.vue';
import { Tabs, TabPane } from 'ant-design-vue';
import EmployerMetrics from './EmployerMetrics.vue';
import MembersMetrics from './MembersMetrics.vue';

export default {
  components: { EmployerMetrics, MembersMetrics, DashStatsQuery, BarChart, Tabs, TabPane },
  data() {
    return {
      activeKey: '1',
    };
  },
  computed: {
    query() {
      return this.$store.state.dash.dashStatsQuery;
    },
    data() {
      return this.$store.getters.dashStatsData;
    },
  },
};
</script>
<style scoped>

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-list, .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list{
  width: 100% !important;
}
.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab-active {
  width: 50% !important;
  text-align: center;
}
</style>
