<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { REWARD } from '@/store/types';
import rewardApi from '../../apis/reward.api';

export default {
  props: {},

  setup() {
    const store = useStore();
    const query = ref(null);
    query.value = rewardApi.useRewardSettingsQuery();
    if (query.value) {
      store.commit(REWARD.SET_REWARD_SETTING_QUERY, query);
      if (query.value.data) {
        console.log('DDDDRRRR qqq', query.value.data);
      }
    }

    return { query };
  },
};
</script>
