<template>
  <div v-if="calcDates">
    <div class="flex items-center justify-end gap-4">
      <div>
        <CustumSelect
          name="spots"
          placeholder="select type "
          :data="spacesData"
          :defaultData="spot"
          :rules="{
        required: false,
      }"
          @setInput="setInput"
        />
      </div>
      <date-picker
        v-if="slideType !== 'Initial'"
        v-model:value="year"
        picker="year"
        :allowClear="false"
      />
    </div>

    <div
      v-if="query && (query.isFetching || query.isLoading)"
      class="flex items-center justify-center py-28 md:py-32 lg:py-36"
    >
      <Spin spinning size="large" />
    </div>

    <QueryAlert :query="query" />

    <div class="min-w-[400px] overflow-x-auto">
      <DonutChart
        v-if="chartData"
        :labelText="totalCommuters"
        :data="chartData"
        :key="componentKey "
      />

      <div v-if="chartData === 0" class="py-24 md:py-28 lg:py-32">
        <Empty />
      </div>

      <div class="mt-4 flex items-center gap-1 text-white">
        <span
          v-if="this.spot !== 4"
          class="bg-primary-light hover:bg-primary h-7 px-4 rounded flex items-center justify-center"
          :class="!showArrows[0] && this.spot !== 3 ? 'opacity-60 cursor-not-allowed' : 'cursor-pointer'"
          @click="this.goPrevStep"
        >
          <PhArrowLeft weight="bold" size="18" />
        </span>
        <Transition v-if="this.spot !== 3" :key="sliderData[slideType][slideIndex]">
          <h3
            :key="sliderData[slideType][slideIndex]"
            class="fade-in-out bg-primary-light text-center text-white h-7 rounded flex-1 flex items-center justify-center"
          >{{sliderData[slideType][slideIndex]}}</h3>
        </Transition>

        <span
          v-if="this.spot !== 4"
          class="bg-primary-light hover:bg-primary h-7 px-4 rounded flex items-center justify-center"
          :class="!showArrows[1] && this.spot !== 3 ? 'opacity-60 cursor-not-allowed' : 'cursor-pointer'"
          @click="this.goNextStep"
        >
          <PhArrowRight weight="bold" size="18" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

import CustumSelect from '@/components/shared/form/CustumSelect.vue';
import { PhArrowLeft, PhArrowRight } from 'phosphor-vue';
import { Spin, DatePicker, Empty } from 'ant-design-vue';
import QueryAlert from '@/components/shared/QueryAlert.vue';

import { sortAndNameMonthes } from '@/utils/monthes';
import DonutChart from '../../shared/DonutChart.vue';
// import COLORS from '../../shared/COLORS';

export default {
  components: {
    CustumSelect,
    PhArrowLeft,
    PhArrowRight,
    DonutChart,
    QueryAlert,
    Spin,
    DatePicker,
    Empty,
  },
  props: {
    initialTitle: String,
    query: Object,
    data: Object,
    defaultYear: {
      type: String,
      default: dayjs(),
    },
    defaultType: {
      type: String,
      default: 'Monthly',
      // Monthly Quarterly Yearly Initial
    },
    startMonth: {
      type: Number,
      default: 1,
    },
    showInitial: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      year: dayjs(),
      spot: 1,
      spacesData: [
        { id: 1, name: 'Monthly' },
        { id: 2, name: 'Quarterly' },
        // { id: 3, name: 'Yearly' },
      ],

      sliderData: {
        Monthly: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ],
        Quarterly: ['First quarter', 'Second quarter', 'Third quarter', 'Fourth quarter'],
        Yearly: ['2022', '2023', '2024'],
        Initial: ['Initial Mode Share'],
      },
      slideType: 'Monthly',
      slideIndex: 0,
      componentKey: 0,
    };
  },

  created() {
    if (this.startMonth !== 1) {
      this.slideIndex = this.startMonth - 1;
    }

    if (this.initialTitle) {
      this.sliderData.Initial = [this.initialTitle];
    }
    if (this.defaultYear) {
      this.year = this.defaultYear;
    }

    if (this.defaultType) {
      this.slideType = this.defaultType;
    }

    if (this.defaultType && this.defaultType === 'Initial') {
      this.spacesData.push({ id: 4, name: 'Initial' });
      this.spot = 4;
    }
    if (this.showInitial) {
      this.spacesData.push({ id: 4, name: 'Initial' });
    }
  },

  computed: {
    getYear() {
      return dayjs(this.year).year();
    },
    showArrows() {
      if (this.slideType === 'Initial') {
        return [false, true];
      }
      const show = [true, true];

      if (this.slideIndex === 0) {
        show[0] = false;
      }
      const arrLen = this.sliderData[this.slideType].length;

      if (this.slideIndex >= arrLen - 1) {
        show[1] = false;
      }

      return show;
    },

    calcDates() {
      if (this.slideType === 'Initial') {
        return ['2010-01-01', '2110-01-01'];
      }

      // YYYY MM DD
      const year = this.getYear;
      let start = '';
      let end = '';
      let from = '';
      let to = '';

      if (this.slideType === 'Monthly') {
        start = this.slideIndex + 1;
        end = this.slideIndex + 1;
      }

      if (this.slideType === 'Quarterly') {
        // 0 => 1 2 3
        // 1 => 4 5 6
        // 2 => 7 8 9
        // 3 => 10 11 12

        start = this.slideIndex + this.slideIndex + (this.slideIndex + 1);
        end = start + 2;
      }

      if (this.slideType === 'Yearly') {
        start = 1;
        end = 12;
      }

      const mon = start < 10 ? `0${start}` : start;
      const nexmon = end < 10 ? `0${end}` : end;

      from = `${year}-${mon}-01`;
      to = dayjs(`${year}-${nexmon}-01`)
        .endOf('month')
        .format('YYYY-MM-DD');

      return [from, to];
    },

    totalCommuters() {
      if (!this.data || !this.data.no_commuters) return 0;

      // const all = [];

      // Object.entries(this.data).forEach(([key, val]) => {
      //   if (key !== 'no_commuters') {
      //     // { label: 'Drive Alone', value: 20 },
      //     const monts = sortAndNameMonthesAndCommuters(val.all);
      //     const commutersVals = monts.map((m) => Object.values(m)[1] || 0);
      //     const total = commutersVals.reduce(
      //       (accumulator, currentValue) => accumulator + currentValue,
      //       0,
      //     );
      //     all.push(total);
      //   }
      // });

      // const sumAll = all.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      // console.log('chartData', sumAll);
      return `${this.data.no_commuters} Commuters`;
      // return `${sumAll} Commuters`;
    },

    chartData() {
      if (!this.data) return null;

      let data = [];
      let allTotal = 0;

      Object.entries(this.data).forEach(([key, val]) => {
        if (key !== 'no_commuters') {
          // { label: 'Drive Alone', value: 20 },
          const monts = sortAndNameMonthes(val.all);
          let total = 0;
          monts.forEach((m) => {
            total += +Object.values(m)[0] || 0;
          });

          allTotal += total;
          data.push({ label: key, value: total });
        }
      });

      if (!allTotal) return 0;

      if (allTotal) {
        data = data.map((d) => ({
          ...d,
          default_value: +d.value,
          value: (+d.value / allTotal) * 100,
        }));
      } else {
        data = data.map((d) => ({
          ...d,
          default_value: +d.value,
          value: (1 / data.length) * 100,
        }));
      }
      this.forceRerender();
      return data;
    },
  },

  watch: {
    calcDates: {
      handler(val) {
        if (val) {
          if (this.slideType === 'Initial') {
            this.$emit('updateDefaultType', 'Initial');
            this.$emit('setDate', val);
          } else this.$emit('setDate', val);
        }
      },
      deep: true,
    },
    startMonth(val) {
      if (val !== 1) {
        console.log('OOO startMonth', val);
        this.slideIndex = val - 1;
      }
    },
  },
  methods: {
    setInput(val) {
      if (val && val.spots) {
        const typeid = val.spots.value;
        this.spot = val.spots.value;
        const spac = this.spacesData.find((d) => d.id === typeid);
        this.$emit('updateDefaultType', spac.name);
        if (spac && this.slideType !== spac.name) {
          this.slideIndex = 0;
          if (spac && spac.name) {
            this.slideType = spac.name;
          }
        }
      }
    },
    goNextStep() {
      if (this.spot === 4) {
        this.slideType = 'Monthly';
        this.slideIndex = 0;
        return;
      }
      if (this.spot !== 3) {
        const maxInx = this.sliderData[this.slideType].length - 1;
        if (this.slideIndex < maxInx) {
          this.slideIndex += 1;
        }
      }
    },
    goPrevStep() {
      if (this.spot !== 3) {
        if (this.slideIndex > 0) {
          this.slideIndex -= 1;
        }
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },
};
</script>

<style scoped>
.fade-in-out {
  animation: fadeInOut 0.4s ease-in-out;
}

@keyframes fadeInOut {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
</style>
