import { STATS } from '../types';

export default {
  state: {
    employerStatsQuery: null,
    commuterBenifitsStatsQuery: null,
    communityFootprintStatsQuery: null,
    modeShareInitialStatsQuery: null,
    modeShareBehaviorStatsQuery: null,
    modeShareMonthStatsQuery: null,
    modeShareCommutersQuery: null,
    modeShareMonthStatsCompareQuery: null,
    mileShareInitialStatsQuery: null,
    mileShareBehaviorStatsQuery: null,
    mileShareMonthStatsQuery: null,
    mileShareCommutersQuery: null,

  },
  getters: {
    employerStatsData(state) {
      if (!state.employerStatsQuery) return null;
      return state.employerStatsQuery.data?.result || null;
    },
    commuterBenifitsStatsData(state) {
      if (!state.commuterBenifitsStatsQuery) return null;
      return state.commuterBenifitsStatsQuery.data?.result || null;
    },
    communityFootprintStatsData(state) {
      if (!state.communityFootprintStatsQuery) return null;
      return state.communityFootprintStatsQuery.data?.result || null;
    },
    modeShareBehaviorStatsData(state) {
      if (!state.modeShareBehaviorStatsQuery) return null;
      return state.modeShareBehaviorStatsQuery.data?.result || null;
    },
    modeShareInitialStatsData(state) {
      if (!state.modeShareInitialStatsQuery) return null;
      const data = state.modeShareInitialStatsQuery.data?.result || null;
      if (!data) return null;
      console.log('dddss', data);

      const pureData = {};

      Object.entries(data).forEach(([key, val]) => {
        if (key !== 'no_commuters') {
          pureData[key] = {
            all: [
              {
                1: {
                  trips_count: val,
                  commuters_count: 2,
                },
              },
            ],
            count: 0,
          };
        } else {
          pureData.no_commuters = val;
        }
      });

      return pureData;
    },
    modeShareMonthStatsData(state) {
      if (!state.modeShareMonthStatsQuery) return null;
      return state.modeShareMonthStatsQuery.data?.result || null;
    },
    modeShareMonthStatsCompareData(state) {
      if (!state.modeShareMonthStatsCompareQuery) return null;
      return state.modeShareMonthStatsCompareQuery.data?.result || null;
    },
    modeShareCommutersData(state) {
      if (!state.modeShareCommutersQuery) return null;
      return state.modeShareCommutersQuery.data?.result || null;
    },

    mileShareBehaviorStatsData(state) {
      if (!state.mileShareBehaviorStatsQuery) return null;
      return state.mileShareBehaviorStatsQuery.data?.result || null;
    },
    mileShareInitialStatsData(state) {
      if (!state.mileShareInitialStatsQuery) return null;
      const data = state.mileShareInitialStatsQuery.data?.result || null;
      if (!data) return null;
      console.log('dddss', data);

      const pureData = {};

      Object.entries(data).forEach(([key, val]) => {
        if (key !== 'no_commuters') {
          pureData[key] = {
            all: [
              {
                1: {
                  trips_count: val,
                  commuters_count: 2,
                },
              },
            ],
            count: 0,
          };
        } else {
          pureData.no_commuters = val;
        }
      });

      return pureData;
    },
    mileShareMonthStatsData(state) {
      if (!state.mileShareMonthStatsQuery) return null;
      return state.mileShareMonthStatsQuery.data?.result || null;
    },
    mileShareCommutersData(state) {
      if (!state.mileShareCommutersQuery) return null;

      const bareData = state.mileShareCommutersQuery.data?.result || null;
      if (!bareData) return null;

      if (!Array.isArray(bareData)) return bareData;

      const mainData = {};

      bareData.forEach((d) => {
        Object.entries(d).forEach(([k, v]) => {
          mainData[k] = v;
        });
      });
      return mainData;
    },

  },
  mutations: {
    [STATS.SET_EMPLOYER_STATS_QUERY](state, payload) {
      state.employerStatsQuery = payload;
    },
    [STATS.SET_COMMUTER_BENEFITS_QUERY](state, payload) {
      state.commuterBenifitsStatsQuery = payload;
    },
    [STATS.SET_COMMUNITY_FOOTPRINT_QUERY](state, payload) {
      state.communityFootprintStatsQuery = payload;
    },
    [STATS.SET_MODE_SHARE_INITIAL_STATS_QUERY](state, payload) {
      state.modeShareInitialStatsQuery = payload;
    },
    [STATS.SET_MODE_SHARE_BEHAVIOR_STATS_QUERY](state, payload) {
      state.modeShareBehaviorStatsQuery = payload;
    },
    [STATS.SET_MODE_MONTH_STATS_QUERY](state, payload) {
      state.modeShareMonthStatsQuery = payload;
    },

    [STATS.SET_MODE_MONTH_STATS_QUERY_COMPARE](state, payload) {
      state.modeShareMonthStatsCompareQuery = payload;
    },

    [STATS.SET_MILE_SHARE_INITIAL_STATS_QUERY](state, payload) {
      state.mileShareInitialStatsQuery = payload;
    },
    [STATS.SET_MILE_SHARE_BEHAVIOR_STATS_QUERY](state, payload) {
      state.mileShareBehaviorStatsQuery = payload;
    },
    [STATS.SET_MILE_MONTH_STATS_QUERY](state, payload) {
      state.mileShareMonthStatsQuery = payload;
    },

    [STATS.SET_MODE_SHARE_COMMUTERS_STATS_QUERY](state, payload) {
      state.modeShareCommutersQuery = payload;
    },

    [STATS.SET_MILE_SHARE_COMMUTERS_STATS_QUERY](state, payload) {
      state.mileShareCommutersQuery = payload;
    },

  },
  actions: {},
};
