/* eslint-disable */
import rewardCalc from '@/components/dashboard/RewardSystem/rewardCalc';
import rewardApi from '@/services/apis/reward.api';
import { message, notification } from 'ant-design-vue';

import { REWARD } from '../types';

const defaultData = {
  mode_base: {
    drive_alone: 20,
    drive_alone_electric: 100,
    public_transit: 100,
    walk: 250,
    bike: 250,
    carpool: 150,
    park: 100,
    telecommute: 100,
    incentives: 10,
  },
  distance_base: {
    drive_alone: 2,
    drive_alone_electric: 10,
    public_transit: 10,
    walk: 25,
    bike: 25,
    carpool: 15,
    park: 10,
    telecommute: 10,
    incentives: 10,
  },
  emission_base: {
    emission: 50,
    incentives: 10,
  },
  fixed_benefits: {
    drive_alone: 5,
    drive_alone_electric: 7.5,
    public_transit: 5,
    walk: 7.5,
    bike: 7.5,
    carpool: 5,
    park: 5,
    telecommute: 2,
  },

};

const apiNormalizedData = {
  mode_base: 'mode_based',
  distance_base: 'mile_based',
  emission_base: 'emission_based',
  fixed_benefits: 'fixed_benfit',
  modes_data: {
    mode_base: {
      drive_alone: 'drive',
      drive_alone_electric: 'drive_ev',
      public_transit: 'transit_drive',
      walk: 'walk',
      bike: 'bike',
      carpool: 'carpool_driver',
      park: 'transit_bike',
      telecommute: 'telecommute',
      incentives: 'thousand_points_incentives',
    },
    distance_base: {
      drive_alone: 'drive',
      drive_alone_electric: 'drive_ev',
      public_transit: 'transit_drive',
      walk: 'walk',
      bike: 'bike',
      carpool: 'carpool_driver',
      park: 'transit_bike',
      telecommute: 'telecommute',
      incentives: 'thousand_points_incentives',
    },
    emission_base: {
      emission: 'point_per_kg_co2_reduction',
      incentives: 'thousand_points_incentives',
    },
    fixed_benefits: {
      drive_alone: 'drive',
      drive_alone_electric: 'drive_ev',
      public_transit: 'transit_drive',
      walk: 'walk',
      bike: 'bike',
      carpool: 'carpool_driver',
      park: 'transit_bike',
      telecommute: 'telecommute',
    },
  },
  average_commute_distance_per_trip: 10,
  signup_incentives: 'signup_incentives',
  employees_number: 'number_of_employees',
  max_reward: 'max_reward_per_employee',

  active_mode: 'distance_base',
  challenge_start: 'challenge_start',

  monthly_prizes: 'monthly_incentives_structure',
  monthly_prizes_data: {
    bike: 'most_miles_biked',
    walk: 'most_miles_walked',
    transit: 'most_transit_trips',
    carpool: 'most_carpool_trips',
    shift_green: 'biggest_shift_from_driving_to_green_trips',
    green: 'most_green_trips',
  },

  is_reward_system_selected: 'scoring_policy_enabled',
  is_signup_incentives_selected: 'signup_incentives_enabled',
  is_monthly_prizes_selected: 'monthly_incentives_enabled',

};

const normalizeSettingsData = (query) => {
  if (!query || !query.data) return null;
  const { data } = query;
  const newState = {};

  newState.modeData.mode_base = {};
  newState.modeData.distance_base = {};
  newState.modeData.emission_base = {};
  newState.modeData.fixed_benefits = {};

  const modeBaseData = data.scoring_policies[apiNormalizedData.modes_data.mode_base];

  console.log('DDDDRRRR', modeBaseData);

  return newState;
};

export default {
  state: {
    modeDefaultData: {
      ...defaultData,
    },
    modeData: {
      ...defaultData,
    },
    average_commute_distance_per_trip: 10,
    signup_incentives: 25,
    employees_number: 150,
    max_reward: 50,

    active_mode: 'distance_base',
    challenge_start: null,

    monthly_prizes: {
      bike: 25,
      walk: 25,
      transit: 25,
      carpool: 25,
      shift_green: 25,
      green: 25,
    },

    is_reward_system_selected: false,
    is_signup_incentives_selected: false,
    is_monthly_prizes_selected: false,

    rewardSettingsQuery: null,
    rewardDefaultsQuery: null,
    rewardsListQuery: null,
    rewardsSummaryQuery: null,
  },
  getters: {

    rewardSettingsData(state) {
      console.log('state.rewardSettingsQuery.data => ', state.rewardSettingsQuery.data);
      if (!state.rewardSettingsQuery) {
        return null;
      }
      return state.rewardSettingsQuery.data || null;
    },
    rewardDefaultsData(state) {
      if (!state.rewardDefaultsQuery) {
        return null;
      }
      return state.rewardDefaultsQuery.data || null;
    },
    /// total signup cost
    totalSignupCosts(state) {
      const rewardData = state.rewardSettingsQuery?.data || null;

      if (!rewardData || !rewardData.signup_incentives_enabled) {
        return 0;
      }

      return rewardCalc.getTotalSignupCosts(rewardData.signup_incentives, rewardData.number_of_employees);
    },
    /// total reward cost
    totalRewardCosts(state) {
      const rewardData = state.rewardSettingsQuery?.data || null;

      if (!rewardData || !rewardData.scoring_policy_enabled) {
        return 0;
      }
      // average * employees
      const total = rewardCalc.getTotalRewardCosts({
        active_mode: rewardData.scoring_policy_type,
        data: rewardData.scoring_policies[rewardData.scoring_policy_type],
        max_reward: rewardData.max_reward_per_employee,
        employees_number: rewardData.number_of_employees,
        average_commute_distance_per_trip: state.average_commute_distance_per_trip,

      });

      return Math.round(total);
    },
    /// total Monthly credit cost
    totalMonthlyCreditCosts(state) {
      const validateWeeklyCreditValue = () => {
          const rewardData = state.rewardSettingsQuery?.data || null;
          let sum = 0;
          const values = rewardData.weekly_credits ?? [{ value: 20, type: 'random_reporter', }] ;

          values.map((item) => {
            sum += item.value;
          });
          return sum;
        }

      const rewardData = state.rewardSettingsQuery?.data || null;

      if (!rewardData || !rewardData.weekly_credit_enabled || !validateWeeklyCreditValue()) {
        return 0;
      }

      return validateWeeklyCreditValue() * 4;
    },
    /// total prizes cost
    totalPrizesCosts(state) {
      const rewardData = state.rewardSettingsQuery?.data || null;

      if (!rewardData || !rewardData.monthly_incentives_enabled) {
        return 0;
      }

      return rewardCalc.getTotalPrizesCosts(rewardData.monthly_incentives_structure);
    },
    /// estimated monthly cost
    totalMonthlyRewardCost(state) {
      // const signupCosts = rewardCalc.getTotalSignupCosts(state.signup_incentives, state.employees_number);

      const rewardData = state.rewardSettingsQuery?.data || null;

      if (!rewardData) {
        return 0;
      }

      // const signupCosts = rewardCalc.getTotalSignupCosts(rewardData.signup_incentives, rewardData.number_of_employees);

      const rewardCosts = rewardCalc.getTotalRewardCosts({
        active_mode: rewardData.scoring_policy_type,
        data: rewardData.scoring_policies[rewardData.scoring_policy_type],
        max_reward: rewardData.max_reward_per_employee,
        employees_number: rewardData.number_of_employees,
        average_commute_distance_per_trip: state.average_commute_distance_per_trip,

      });
      const prizesCosts = rewardCalc.getTotalPrizesCosts(rewardData.monthly_incentives_structure);

      const weeklyCreditCosts = rewardCalc.getWeeklyCreditCosts(rewardData.weekly_credits ?? [{ value: 20, type: 'random_reporter', }]);

      // const signupCostsFinal = rewardData.signup_incentives_enabled ? signupCosts : 0;
      const rewardCostsFinal = rewardData.scoring_policy_enabled ? rewardCosts : 0;
      const prizesCostsFinal = rewardData.monthly_incentives_enabled ? prizesCosts : 0;
      const weeklyCreditCostsFinal = rewardData.weekly_credit_enabled ? weeklyCreditCosts : 0;

      // const total = signupCostsFinal + rewardCostsFinal + prizesCostsFinal;
      const total = rewardCostsFinal + prizesCostsFinal + weeklyCreditCostsFinal;

      return Math.round(total);
    },

    /// estimated tax cost
    totalTaxCreditCost(state) {
      const rewardData = state.rewardSettingsQuery?.data || null;

      if (!rewardData) {
        return 0;
      }
      const taxCosts = rewardCalc.getTotalTaxsCosts({
        active_mode: rewardData.scoring_policy_type,
        data: rewardData.scoring_policies[rewardData.scoring_policy_type],
        max_reward: rewardData.max_reward_per_employee,
        employees_number: rewardData.number_of_employees,
        average_commute_distance_per_trip: state.average_commute_distance_per_trip,

      });

      return Math.round(taxCosts);
    },
  },
  mutations: {
    [REWARD.SET_MODE_DATA](state, { key, data }) {
      state.modeData = {
        ...state.modeData,
        [key]: data,
      };
    },
    [REWARD.SET_SIGNUP_INCENTIVES_DATA](state, payload) {
      state.signup_incentives = payload;
    },
    [REWARD.SET_EMPLOYEES_NUMBER_DATA](state, payload) {
      state.employees_number = payload;
    },
    [REWARD.SET_MAX_REWARD_NUMBER_DATA](state, payload) {
      state.max_reward = payload;
    },
    [REWARD.SET_ACTIVE_MODE_DATA](state, payload) {
      state.active_mode = payload;
    },
    [REWARD.SET_MONTHLY_PRIZES_DATA](state, { key, value }) {
      state.monthly_prizes = {
        ...state.monthly_prizes,
        [key]: value,
      };
    },
    [REWARD.SET_SELECTED_SYSTEM](state, payload) {
      state[payload.type] = payload.value;
    },
    [REWARD.SET_REWARD_SETTING_QUERY](state, payload) {
      state.rewardSettingsQuery = payload;

      normalizeSettingsData(payload);
    },
    [REWARD.SET_REWARD_DEFAULTS_QUERY](state, payload) {
      state.rewardDefaultsQuery = payload;
    },

    [REWARD.SET_REWARDS_LIST_QUERY](state, payload) {
      state.rewardsListQuery = payload;
    },
    [REWARD.SET_REWARDS_SUMMARY_QUERY](state, payload) {
      state.rewardsSummaryQuery = payload;
    },

  },
  actions: {
    async [REWARD.ACTION_SELECT_REWARD_SYS]({ state }, payload) {
      if (!state.rewardSettingsQuery || !state.rewardSettingsQuery.data) {
        return null;
      }
      const currentData = state.rewardSettingsQuery.data;

      // const challenge_start = currentData.challenge_start || dayjs().add(1, 'month').startOf('month').format('YYYY-MM-DD');

      try {
        await rewardApi.updateRewardSettings({
          ...currentData,
          ...payload,
          // challenge_start,
        });

        state.rewardSettingsQuery.refetch();
        // const data = await authApi.getUser();
        // commit(AUTH.SET_USER_DATA, data);
        return null;
      } catch (error) {
        notification.error({
          message: error.message,
          description: error?.response?.data?.message || error.message || 'Something went wrong',
          duration: 6,
        });

        return { error: 'incorrect email or password' };
      }
    },

    async [REWARD.ACTION_UPDATE_REWARD_SYS]({ state }, payload) {
      if (!state.rewardSettingsQuery || !state.rewardSettingsQuery.data) {
        return null;
      }
      const currentData = state.rewardSettingsQuery.data;

      try {
        await rewardApi.updateRewardSettings({
          ...currentData,
          ...payload,
        });

        state.rewardSettingsQuery.refetch();
        message.success('Settings updated successfully');
        // const data = await authApi.getUser();
        // commit(AUTH.SET_USER_DATA, data);
        return null;
      } catch (error) {
        notification.error({
          message: error.message,
          description: error?.response?.data?.message || error.message || 'Something went wrong',
          duration: 6,
        });

        return { error: 'incorrect email or password' };
      }
    },
  },

};
