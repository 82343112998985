/* eslint-disable */
<template>
  <section class="members-data">
    <div class="w-full mt-4 flex flex-wrap items-center justify-between gap-4">
      <div class="flex items-center gap-3 flex-wrap">
        <Spin :spinning="employersQuery && employersQuery.isLoading">
          <Select
            class="text-center min-w-[300px]"
            :value="employer_id"
            @change="handleSelectBusiness"
            allowClear
            placeholder="Select business"
          >
            <select-option v-for="buss in employersData" :key="buss._id" :value="buss._id">{{
              buss.community_name
            }}</select-option>
          </Select>
        </Spin>
      </div>
      <div class="flex flex-wrap items-center gap-6">
        <div>
          <InputSearch
            class="rounded-md min-w-[320px]"
            placeholder="Search ..."
            v-model:value="search"
            clear
            @change="handleSearch"
          />
        </div>

        <div class="flex items-center gap-3">
          <div>
            <date-picker v-if="type !== 'all'" :picker="type" v-model:value="dateVal" />
          </div>

          <CustumSelect
            name="type"
            placeholder="select type "
            :data="dateData"
            :defaultData="type"
            :rules="{
              required: false,
            }"
            @setInput="setInput"
          />
          <CustumSelect
            name="status"
            class="min-w-[100px]"
            placeholder="select status "
            :data="statusData"
            :defaultData="searchStatus"
            :rules="{
              required: false,
            }"
            @setInput="setStatus"
          />
        </div>
      </div>
    </div>

    <QueryAlert :query="employersQuery" />
    <QueryAlert :query="adminInvoicesQuery" />

    <Modal
      :visible="creditModal"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      @ok="creditModal = false"
      @cancel="creditModal = false"
    >
      <div class="p-4">
        <h2>Message of rejection</h2>
        <Row>
          <Col :span="24">
            <CustumInput
              name="credit_value"
              label="Message"
              :rules="{
                required: true,
                min: 1,
              }"
              @setInput="setInput2"
            />
          </Col>
        </Row>
        <Button
          type="primary"
          block
          class="mt-4"
          :loading="addCreditLoading"
          @click="handleAddCredit"
          >Reject</Button
        >
      </div>
    </Modal>

    <div class="mt-2 md:mt-3 lg:mt-4">
      <Table
        :data-source="tableFormatedData"
        :columns="columns"
        :loading="
          adminInvoicesQuery && (adminInvoicesQuery.isFetching || adminInvoicesQuery.isLoading)
        "
        :pagination="false"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'employee_name'">
            <span v-if="record?.employee_name" class="whitespace-nowrap">{{
              record?.employee_name
            }}</span>
            <span v-else class="w-full flex justify-center text-yellow-600">N/A</span>
          </template>
          <template v-if="column.key === 'email_address'">
            <span v-if="record?.email_address" class="whitespace-nowrap">{{
              record?.email_address
            }}</span>
            <span v-else class="w-full flex justify-center text-yellow-600">N/A</span>
          </template>
          <template v-if="column.key === 'balance'">
            <span v-if="record?.total_credit" class="whitespace-nowrap">{{
              record?.total_credit - record?.redeemed_balance
            }}</span>
            <span v-else class="w-full flex justify-center text-yellow-600">N/A</span>
          </template>
          <template v-if="column.key === 'redeemed'">
            <span v-if="record?.redeemed_balance" class="whitespace-nowrap">{{
              record?.redeemed_balance
            }}</span>
            <span v-else class="w-full flex justify-center text-yellow-600">N/A</span>
          </template>
          <template v-if="column.key === 'not_redeemed'">
            <span v-if="record?.total_credit" class="whitespace-nowrap">{{
              record?.total_credit
            }}</span>
            <span v-else class="w-full flex justify-center text-yellow-600">N/A</span>
          </template>

          <template v-if="column.key === 'joined_on'">
            <span v-if="record?.joined_on" class="whitespace-nowrap">{{
              getDate(record?.joined_on)
            }}</span>
            <span v-else class="w-full flex justify-center text-yellow-600">N/A</span>
          </template>
          <template v-if="column.key === 'tax_doc'">
            <div class="flex items-center gap-2">
              <Button
                type="primary"
                size="small"
                ghost
                @click="() => handleGeneratePdf(record?.tax_doc)"
                :loading="pdfLoading === record.key && !detailsLoading"
              >
                <div class="flex items-center gap-2" v-if="pdfLoading !== record.key">
                  <PhFilePdf weight="duotone" :size="16" />PDF File
                </div>
              </Button>
            </div>
          </template>
          <template v-if="column.key === 'action'">
            <div class="flex items-center gap-2">
              <!-- Decline  -->
              <Popconfirm
                title="Are you sure you want to decline?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="
                  () => {
                    handleConfirm(record?.key, 'rejected');
                  }
                "
              >
                <Button
                  type="primary"
                  size="small"
                  title="Decline"
                  class="btn-warning rounded shadow flex items-center gap-2"
                  :loading="statusLoading"
                >
                  <PhX weight="duotone" :size="16" />
                </Button>
              </Popconfirm>

              <!-- Approve  -->
              <Popconfirm
                title="Are you sure you want to approve?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="
                  () => {
                    handleConfirm(record?.key, 'approved');
                  }
                "
                :loading="statusLoading"
              >
                <Button
                  type="primary"
                  size="small"
                  title="Approve"
                  class="rounded shadow flex items-center gap-2"
                >
                  <PhCheck weight="duotone" :size="16" />
                </Button>
              </Popconfirm>
            </div>
          </template>
        </template>
      </Table>

      <div
        v-if="adminInvoicesData && adminInvoicesData.meta"
        class="mt-6 flex items-center justify-center"
      >
        <Pagination
          :current="adminInvoicesData?.meta?.current_page"
          :total="adminInvoicesData?.meta?.total"
          show-less-items
          :page-size="adminInvoicesData?.meta?.per_page"
          @change="handlePaginationChange"
        />
      </div>
    </div>

    <!-- query apis  -->
    <AdminInvoicesQuery
      :page="page"
      :per_page="15"
      :employer_id="employer_id"
      :start_date="dates[0]"
      :end_date="dates[1]"
      :keyword="keyword"
      :status="searchStatus"
      :key="`${page}-${15}-${employer_id}-${dates[0]}-${dates[1]}-${keyword}-${searchStatus}`"
    />
    <EmployersQuery />
  </section>
</template>
<script>
/* eslint-disable */
import {
  Table,
  InputSearch,
  Select,
  SelectOption,
  Spin,
  DatePicker,
  Pagination,
  message,
  Popconfirm,
  Button,
  Modal,
  Row,
  Col,
} from 'ant-design-vue';
import dayjs from 'dayjs';

import EmployersQuery from '@/services/queries/admin/employers/EmployersQuery.vue';
import AdminInvoicesQuery from '@/services/queries/admin/taxesApproval/taxesApprovalQuery.vue';
import changeTaxApprovalStatus from '@/services/apis/admin/tax.api.js';
import debounce from 'lodash.debounce';

// import handleResErrors from '@/utils/handleResErrors';
import CustumSelect from '@/components/shared/form/CustumSelect.vue';

import { getAdminToken } from '@/services/jwt.service';
import { PhCheck, PhFilePdf, PhProhibit, PhTrash, PhX } from 'phosphor-vue';
import taxApi from '@/services/apis/admin/tax.api.js';
import handleResErrors from '../../../utils/handleResErrors';
import CustumInput from '@/components/shared/form/CustumInput.vue';

export default {
  components: {
    Table,
    PhCheck,
    PhFilePdf,
    PhProhibit,
    Row,
    Col,
    CustumInput,
    Modal,
    PhTrash,
    PhX,
    InputSearch,
    PhFilePdf,
    Select,
    SelectOption,
    Popconfirm,
    AdminInvoicesQuery,
    EmployersQuery,
    Spin,
    Button,
    DatePicker,
    CustumSelect,
    Pagination,
  },
  data() {
    return {
      columns: [
        {
          title: 'Employee Name',
          dataIndex: 'employee_name',
          key: 'employee_name',
        },
        {
          title: 'Email address',
          dataIndex: 'email_address',
          key: 'email_address',
        },
        {
          title: 'Total credit',
          dataIndex: 'not_redeemed',
          key: 'not_redeemed',
        },
        {
          title: 'Redeemed credit',
          dataIndex: 'redeemed',
          key: 'redeemed',
        },
        {
          title: 'Remaining credit (balance)',
          dataIndex: 'balance',
          key: 'balance',
        },
        {
          title: 'Joined on',
          dataIndex: 'joined_on',
          key: 'joined_on',
        },
        {
          title: 'Document Status',
          dataIndex: 'status',
          key: 'status',
        },
        {
          title: 'Tax document',
          dataIndex: 'tax_doc',
          key: 'tax_doc',
        },
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
        },
      ],

      tableData: [
        {
          key: '1',
          invoice_date: 1681478087456,
          service: 'service 1',
          type: 'type',
          amount_status: 'amount_status',
          amount: 22,
          invoice: 'invoice',
        },

        ...Array(50)
          .fill('dd')
          .map((_, ix) => ({
            key: ix + 2,
            name: `Name ${ix + 2}`,
            invoice_date: this.getRandomInt(1420063200000, 1681478087456),
            service: `service  ${ix + 2}`,
            type: 'type',
            amount_status: 'amount_status',
            amount: this.getRandomInt(11, 111),
            invoice: `invoice ${ix + 2}`,
          })),
      ],
      search: '',
      searchData: [],
      page: 1,
      pageSize: 10,
      employer_id: null,
      type: 'year',
      searchStatus: null,
      addCreditLoading: false,

      years: [],
      dateData: [
        // { id: 1, name: 'custom date' },
        { id: 'month', name: 'Monthly' },
        { id: 'year', name: 'Yearly' },
        { id: 'all', name: 'All Data' },
      ],
      statusData: [
        // { id: 1, name: 'custom date' },
        { id: 'pending', name: 'pending' },
        { id: 'approved', name: 'approved' },
        { id: 'rejected', name: 'rejected' },
      ],
      dateVal: dayjs(new Date().getFullYear()+'/12', 'YYYY/MM'),
      keyword: '',
      pdfLoading: false,
      rejectionMsg: '',
      creditModal: false,
      modalUserId: null,
      detailsLoading: false,
    };
  },
  computed: {
    dates() {
      if (!this.dateVal) return [null, null];
      if (this.type === 'month') {
        const start = dayjs(this.dateVal).startOf('month').format('YYYY-MM-DD');
        const end = dayjs(this.dateVal).endOf('month').format('YYYY-MM-DD');
        return [start, end];
      }

      if (this.type === 'year') {
        const start = dayjs(this.dateVal).startOf('year').format('YYYY-MM-DD');
        const end = dayjs(this.dateVal).endOf('year').format('YYYY-MM-DD');
        return [start, end];
      }

      return [null, null];
    },
    employersQuery() {
      return this.$store.state.adminEmployers.employersQuery;
    },
    employersData() {
      return this.$store.getters.employersData?.data || [];
    },
    adminInvoicesQuery() {
      return this.$store.state.adminInvoices.adminInvoicesQuery;
    },
    adminInvoicesData() {
      return this.$store.getters.adminInvoicesData;
    },
    tableFormatedData() {
      if (!this.adminInvoicesData || !this.adminInvoicesData.data) return [];

      const data = this.adminInvoicesData?.data.map((d, ix) => ({
        key: d?._id || ix,
        employee_name: d?.user?.name,
        email_address: d?.user?.email,
        balance: d?.balance.toFixed(2),
        status: d?.status,
        joined_on: d?.user?.created_at,
        tax_doc: d?.approval_file_url,
        redeemed_balance: Number(d?.redeemed_balance)?.toFixed(2),
        not_redeemed: d?.total_benfits?.toFixed(2),
        total_credit: d?.total_benfits?.toFixed(2),
      }));
      return data;
    },
  },
  methods: {
    async handleAddCredit() {
      try {
        const res = await taxApi.changeTaxApprovalStatus(
          this.modalUserId,
          'rejected',
          this.rejectionMsg,
        );
        if (res) {
          message.success('Status updated successfully');
          this.creditModal = false;

        }
        if (this.adminInvoicesQuery) {
          await this.adminInvoicesQuery.refetch();
        }
      } catch (error) {
        message.error(handleResErrors(error), 10);
      } finally {
        // this.statusLoading = false;
      }
    },
    async handleConfirm(id, status) {
      // this.statusLoading = true;
      try {
        if (status != 'rejected') {
          const res = await taxApi.changeTaxApprovalStatus(id, status);
          if (res) {
            message.success('Status updated successfully');
          }
          if (this.adminInvoicesQuery) {
            await this.adminInvoicesQuery.refetch();
          }
        } else {
          this.modalUserId = id;
          this.creditModal = true;
        }
      } catch (error) {
        message.error(handleResErrors(error), 10);
      } finally {
        // this.statusLoading = false;
      }
    },
    setInput2(val) {
      if (val.credit_value && val.credit_value.value) {
        this.rejectionMsg = val.credit_value.value;
      }
    },
    handlePaginationChange(page, pageSize) {
      // , filters, sorter, data
      this.page = page;
      this.pageSize = pageSize;
    },
    getRandomInt(min, max) {
      const xmin = Math.ceil(min);
      const xmax = Math.floor(max);
      return Math.floor(Math.random() * (xmax - xmin + 1)) + min;
    },
    getDate(d) {
      // February 18, 2022, 4:43 am
      return dayjs(d).format('MMM DD, YYYY');
    },
    handleSearch(e) {
      this.debounceSearch(e.target.value, this);
    },
    debounceSearch: debounce((search, app) => {
      // eslint-disable-next-line no-param-reassign
      app.keyword = search;
      // eslint-disable-next-line no-param-reassign
      app.page = 1;
    }, 400),
    handleSelectBusiness(val) {
      this.page = 1;
      this.employer_id = val;
    },
    setInput(val) {
      if (val.type && val.type.value) {
        this.type = val.type.value;
      }
    },
    setStatus(val) {
      this.searchStatus = val?.status?.value;
    },
    async handleGeneratePdf(id, details = 0) {
      this.pdfLoading = id;
      if (details) this.detailsLoading = true;
      try {
        this.openPdf(id, this.employer_id, details);
        // await adminInvoicesApi.viewAdminInvoice({ id, employer_id: this.employer_id, details });
      } catch (error) {
        message.error(handleResErrors(error));
      } finally {
        this.pdfLoading = false;
        this.detailsLoading = false;
      }
    },
    openPdf(id, employer_id = null, details = 0) {
      const token = getAdminToken();
      if (!token || !id) {
        throw new Error('Missing token or id');
      }
      /* eslint-disable */
      let url = '';
      if (details != 0) url += '&detailed=1';
      if (employer_id) url += `&employer_id=${employer_id}`;
      console.log('invoices');
      window.open(`${id}`, '_blank') || window.location.replace(`${id}`);
      return;
    },
  },
};
</script>
