import { ADMIN_INVOICES } from '../../types';

export default {
  state: {
    taxesApprovalQuery: null,

  },
  getters: {
    taxesApprovalData(state) {
      if (!state.taxesApprovalQuery) {
        return null;
      }
      return state.taxesApprovalQuery.data || null;
    },
  },
  mutations: {
    [ADMIN_INVOICES.SET_INVOICES_QUERY](state, payload) {
      state.taxesApprovalQuery = payload;
    },
  },
  actions: {

  },

};
