const STATS = {
  SET_EMPLOYER_STATS_QUERY: 'SET_EMPLOYER_STATS_QUERY',
  SET_COMMUNITY_FOOTPRINT_QUERY: 'SET_COMMUNITY_FOOTPRINT_QUERY',
  SET_COMMUTER_BENEFITS_QUERY: 'SET_COMMUTER_BENEFITS_QUERY',
  SET_MODE_SHARE_BEHAVIOR_STATS_QUERY: 'SET_MODE_SHARE_BEHAVIOR_STATS_QUERY',
  SET_MODE_SHARE_INITIAL_STATS_QUERY: 'SET_MODE_SHARE_INITIAL_STATS_QUERY',
  SET_MODE_MONTH_STATS_QUERY: 'SET_MODE_MONTH_STATS_QUERY',
  SET_MILE_SHARE_BEHAVIOR_STATS_QUERY: 'SET_MILE_SHARE_BEHAVIOR_STATS_QUERY',
  SET_MILE_SHARE_INITIAL_STATS_QUERY: 'SET_MILE_SHARE_INITIAL_STATS_QUERY',
  SET_MILE_MONTH_STATS_QUERY: 'SET_MILE_MONTH_STATS_QUERY',
  SET_MODE_SHARE_COMMUTERS_STATS_QUERY: 'SET_MODE_SHARE_COMMUTERS_STATS_QUERY',
  SET_MILE_SHARE_COMMUTERS_STATS_QUERY: 'SET_MILE_SHARE_COMMUTERS_STATS_QUERY',
  SET_MODE_MONTH_STATS_QUERY_COMPARE: 'SET_MODE_MONTH_STATS_QUERY_COMPARE',
};

export default STATS;
