import { ADMIN_TAX_APPROVAL } from '../../types';

export default {
  state: {
    adminInvoicesQuery: null,

  },
  getters: {
    adminInvoicesData(state) {
      if (!state.adminInvoicesQuery) {
        return null;
      }
      return state.adminInvoicesQuery.data || null;
    },
  },
  mutations: {
    [ADMIN_TAX_APPROVAL.SET_TAX_APPROVAL](state, payload) {
      state.adminInvoicesQuery = payload;
    },
  },
  actions: {

  },

};
