<template>
  <router-view />
</template>

<script>
// import { initDarkMode } from './utils/handleDarkMode';
// import { AUTH } from './store/types';
// import { getToken } from './services/jwt.service';

export default {
  beforeCreate() {
    // initDarkMode();
    // if (!this.$store.state.auth.isAuth) {
    //   this.$store.commit(AUTH.SET_AUTH, getToken());
    // }
  },
  computed: {
    // isAuth() {
    //   return this.$store.state.auth.isAuth;
    // },
  },
  watch: {
    // isAuth(val) {
    //   if (!val && !this.$route.path.startsWith('/auth/')) {
    //     // watch logout
    //     this.$router.push('/auth/login');
    //   }
    // },
  },
  mounted() {
    console.info('app has started, Welcome on board..');
  },
};
</script>
