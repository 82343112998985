<template>
  <section>
    <div v-if="rewardData" class="w-full main-card px-3 md:px-4 lg:px-5 py-4 lg:py-5 xl:py-7">
      <div class="text-center max-w-xl my-6 flex flex-col gap-2 items-center mx-auto">
        <div>
          <img src="@/assets/icons/group-of-users.png" alt="group of users icon" class="h-28" />
        </div>
        <h2 class="text-primary-dark">Choose Reward System for Your Community</h2>
        <p>Gamification is an important element to motivate employees earns rewards in a friendly and competitive environment</p>
      </div>

      <!-- <CommuteBenefits /> -->
      <IncentivesReward :isOngoing="isOngoing" :hasStarted='hasStarted' />
      <RewardSystem :isOngoing="isOngoing" :hasStarted='hasStarted' />
      <WeeklyCredit :isOngoing="isOngoing" :hasStarted='hasStarted'/>
      <MonthlyPrizes :isOngoing="isOngoing" :hasStarted='hasStarted'/>
      <EstimatedCost :isOngoing="isOngoing" :hasStarted='hasStarted'/>

      <!-- <div
        class="rounded-lg p-3 lg:p-5 bg-bg border border-bg-dark border-solid mt-5 flex items-center justify-center"
      >
        <HeaderBalance vertical />
      </div>-->
    </div>
  </section>
</template>

<script>
// import CommuteBenefits from './reward/CommuteBenefits.vue';
// import HeaderBalance from '@/components/dashboard/CreditDetails/HeaderBalance.vue';
import dayjs from 'dayjs';
import RewardSystem from './reward/RewardSystem.vue';
import IncentivesReward from './reward/IncentivesReward.vue';
import MonthlyPrizes from './reward/MonthlyPrizes.vue';
import EstimatedCost from './reward/EstimatedCost.vue';
import WeeklyCredit from './reward/WeeklyCredit.vue';

export default {
  components: {
    // CommuteBenefits,
    RewardSystem,
    IncentivesReward,
    MonthlyPrizes,
    EstimatedCost,
    WeeklyCredit,
  },
  computed: {
    rewardData() {
      return this.$store.getters.rewardSettingsData;
    },
    isOngoing() {
      console.log('this.rewardData => ', this.rewardData);
      const endData = this.rewardData.end_date;
      if (!endData) return false;

      const endDateVal = dayjs(endData);
      const today = dayjs();
      const isBefore = today.isBefore(endDateVal);

      return isBefore;
    },
    hasStarted() {
      /* eslint-disable */
      const startDate = this.rewardData.start_date;
      if (!startDate) return false;

      const startDateVal = dayjs(startDate);
      const today = dayjs();

      const isAfter = today.isAfter(startDateVal) || (today.isSame(startDate));

      return isAfter;
    },
  },
};
</script>
