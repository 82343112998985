<template>
  <div class="w-full p-2">
    <Button
      type="primary"
      size="large"
      class="btn-gradient"
      @click="() => connectedCharts = ~connectedCharts"
    >
      <span v-if="connectedCharts">Disconnect Charts</span>
      <span v-else>Connect Charts</span>
    </Button>
    <div class="mt-6 grid grid-cols-1 xl:grid-cols-2 gap-10 items-end">
      <MainChart
        :query="initialQuery"
        :data="initialData"
        defaultType="Initial"
        initialTitle="Initial Mile Share"
        @setDate="setInitialDate"
      />

      <MainChart
        :query="filiterQuery"
        :data="filiterData"
        :defaultYear="defaultFilterYear"
        @setDate="setFiliterDate"
        :startMonth="startMonth"
        :key="defaultFilterYear + '-' + startMonth"
      />
    </div>

    <ReportChart
      queryname="mileShareBehaviorStatsQuery"
      gettername="mileShareBehaviorStatsData"
      :commutersData="commutersData"
      :year="reportYear"
      title="Commute Behavior Report"
      filter="Miles"
      @setDate="setReportDate"
    />

    <!-- api queries  -->
    <StatsMileShareInitialQuery
      :from="initialDate[0]"
      :to="initialDate[1]"
      :key="initialDate[0]+initialDate[1]"
    />
    <StatsMileMonthQuery
      v-if="filiterDate"
      :from="filiterDate[0]"
      :to="filiterDate[1]"
      :key="filiterDate[0]+filiterDate[1]"
    />
    <StatsMileShareBehaviorQuery
      v-if="reportDate"
      :from="reportDate[0]"
      :to="reportDate[1]"
      :key="reportDate[0]+reportDate[1]"
    />

    <StatsMileShareCommutersQuery
      v-if="reportDate"
      :from="reportDate[0]"
      :to="reportDate[1]"
      :key="reportDate[0]+reportDate[1]"
    />
  </div>
</template>

<script>
import StatsMileShareInitialQuery from '@/services/queries/stats/StatsMileShareInitialQuery.vue';
import StatsMileShareBehaviorQuery from '@/services/queries/stats/StatsMileShareBehaviorQuery.vue';
import StatsMileMonthQuery from '@/services/queries/stats/StatsMileMonthQuery.vue';

import { Button } from 'ant-design-vue';
import dayjs from 'dayjs';
import StatsMileShareCommutersQuery from '@/services/queries/stats/StatsMileShareCommutersQuery.vue';
import ReportChart from './charts/ReportChart.vue';
import MainChart from './charts/MainChart.vue';
import getActiveMonth from '../shared/getActiveMonth';

export default {
  components: {
    ReportChart,
    Button,
    MainChart,
    StatsMileShareInitialQuery,
    StatsMileShareBehaviorQuery,
    StatsMileMonthQuery,
    StatsMileShareCommutersQuery,
  },

  data() {
    return {
      reportYear: dayjs(),
      defaultFilterYear: dayjs(),

      reportDate: null,
      filiterDate: null,
      initialDate: [null, null],
      startMonth: 1,
      connectedCharts: false,
      checkLoading: false,
      filterDataChanged: false,
    };
  },

  created() {
    const currYear = dayjs().year();
    const yearDate = [`${currYear}-01-01`, `${currYear}-12-31`];

    if (!this.reportDate) {
      this.reportDate = yearDate;
    }
    if (!this.filiterDate) {
      this.filiterDate = yearDate;
    }
  },

  computed: {
    initialQuery() {
      return this.$store.state.stats.mileShareInitialStatsQuery;
    },
    initialData() {
      return this.$store.getters.mileShareInitialStatsData;
    },
    filiterQuery() {
      return this.$store.state.stats.mileShareMonthStatsQuery;
    },
    filiterData() {
      return this.$store.getters.mileShareMonthStatsData;
    },
    reportData() {
      return this.$store.getters.mileShareBehaviorStatsData;
    },
    commutersData() {
      return this.$store.getters.mileShareCommutersData;
    },
  },
  watch: {
    reportData: {
      async handler(val) {
        if (!this.checkLoading && val && !this.filterDataChanged) {
          this.checkLoading = true;
          const { firstMonth, year } = await getActiveMonth(
            val,
            this.startMonth,
            new Date().getFullYear(),
            'mile_share',
          );

          if (firstMonth) {
            this.startMonth = firstMonth;
            this.filterDataChanged = true;
          }
          if (year && year !== dayjs(this.defaultFilterYear).year()) {
            const endMon = dayjs(`${year}-${firstMonth ? firstMonth + 1 : 1}-01`)
              .endOf('month')
              .format('YYYY-MM-DD');
            this.setFiliterDate([`${year}-01-01`, endMon]);
            this.defaultFilterYear = dayjs().year(year);

            // change behavior report chart default year
            this.reportYear = dayjs().year(year);
            const currYear = dayjs()
              .year(year)
              .year();
            this.reportDate = [`${currYear}-01-01`, `${currYear}-12-31`];
          }
          this.checkLoading = false;
        }
      },
      deep: true,
    },
    connectedCharts(val) {
      if (val) {
        this.initialDate = this.filiterDate;
      } else {
        this.initialDate = [null, null];
      }
    },
  },
  methods: {
    setReportDate(y) {
      this.reportYear = y;
      const currYear = dayjs(y).year();
      const reportDate = [`${currYear}-01-01`, `${currYear}-12-31`];
      this.reportDate = reportDate;
    },
    setFiliterDate(dates) {
      this.filiterDate = dates;
      if (this.connectedCharts) {
        this.initialDate = dates;
      }
    },
    setInitialDate(dates) {
      this.initialDate = dates;
    },
  },
};
</script>
