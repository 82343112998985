<template>
  <div class="account-page py-4 mt-4">
    <div class="container">
      <div class="w-full main-card px-3 md:px-4 lg:px-5 py-4 lg:py-5 xl:py-7">
        <tabs v-model:activeKey="activeKey" class="w-full">
          <tab-pane key="1" tab="Account">
            <AccountInfo />
          </tab-pane>
          <!--
          <tab-pane v-if="isDev" key="2" tab="Payment Methods">
            <PaymentMethods />
          </tab-pane>-->
          <tab-pane key="3" tab="Invoices">
            <PaymentInvoices />
          </tab-pane>
          <tab-pane v-if="isDev" key="4" :tab="isDev ? 'OLD Subscription' : 'Subscription'">
            <AccountSubscriptionOld />
          </tab-pane>
          <tab-pane key="5" tab="Payments">
            <Payments />
          </tab-pane>
          <tab-pane key="6" tab="Subscription">
            <AccountSubscription />
          </tab-pane>
        </tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { Tabs, TabPane } from 'ant-design-vue';

import Payments from '@/components/dashboard/payments/payment/MainIndex.vue';
import AccountInfo from './AccountInfo.vue';
// import PaymentMethods from '../payments/PaymentMethods.vue';
import PaymentInvoices from '../payments/PaymentInvoices.vue';
import AccountSubscription from './AccountSubscription.vue';
import AccountSubscriptionOld from './AccountSubscriptionOld.vue';

export default {
  components: {
    Tabs,
    TabPane,
    AccountInfo,
    PaymentInvoices,
    AccountSubscription,
    AccountSubscriptionOld,
    Payments,
  },
  data() {
    return {
      activeKey: '1',
    };
  },
  created() {
    // check query params for active tab
    const { tab } = this.$route.query;
    if (tab) {
      this.activeKey = tab;
    }
  },
  computed: {
    isDev() {
      return process.env.VUE_APP_DEV === 'development' || process.env.NODE_ENV === 'development';
    },
  },
};
</script>

<style  >
.account-page .ant-tabs-tab {
  font-size: 1.25rem;
}
</style>
