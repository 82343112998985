import { SCORES } from '../types';

export default {
  state: {
    scoresQuery: null,
  },
  getters: {
    scoresData(state) {
      if (!state.scoresQuery) {
        return null;
      }

      return state.scoresQuery.data?.result || null;
    },
  },
  mutations: {
    [SCORES.SET_SCORES_QUERY](state, payload) {
      state.scoresQuery = payload;
    },

  },
  actions: {

  },

};
