import { useQuery } from 'vue-query';
import readAndDownloadFile from '@/utils/downloadFile';
import apiAxios from '../apiAxios';

const fetchRewardSettings = async () => {
  const { data } = await apiAxios.get('/reward-settings');
  return data;
};

const fetchRewardDefaults = async () => {
  const { data } = await apiAxios.get('/reward-settings/default-scoring-policies');
  return data;
};

const updateRewardSettings = async (payload) => {
  const { data } = await apiAxios.post('/reward-settings', payload);
  return data;
};

const uploadNotifyFile = async (payload) => {
  const { data } = await apiAxios.post('/reward-settings/notify-all', payload);
  return data;
};

const fetchRewardsList = async ({ startDate, endDate }) => {
  let url = '/rewards';
  if (startDate && endDate) {
    url = `/rewards?start_date=${startDate}&end_date=${endDate}`;
  }

  const { data } = await apiAxios.get(url);
  return data;
};

const fetchRewardsSummary = async ({ startDate, endDate }) => {
  let url = '/rewards-summary';
  if (startDate && endDate) {
    url = `/rewards-summary?start_date=${startDate}&end_date=${endDate}`;
  }
  const { data } = await apiAxios.get(url);
  return data;
};

const downloadRewardsList = async ({ startDate, endDate }) => {
  let url = '/rewards?export=1';
  if (startDate && endDate) {
    url += `&start_date=${startDate}&end_date=${endDate}`;
  }

  const { data } = await apiAxios.get(url, { responseType: 'blob' });

  readAndDownloadFile(data);

  return data;
};

const downloadRewardsSummaryList = async ({ startDate, endDate }) => {
  let url = '/rewards-summary?export=1';
  if (startDate && endDate) {
    url += `&start_date=${startDate}&end_date=${endDate}`;
  }

  const { data } = await apiAxios.get(url, { responseType: 'blob' });

  readAndDownloadFile(data);

  return data;
};

// queries
const useRewardSettingsQuery = () => useQuery(['reward-settings'], fetchRewardSettings);
const useRewardDefaultsQuery = () => useQuery(['reward-settings/default-scoring-policies'], fetchRewardDefaults);

const useRewardsListQuery = ({ startDate, endDate }) => useQuery(['rewards', startDate, endDate], () => fetchRewardsList({ startDate, endDate }));
const useRewardsSummaryQuery = ({ startDate, endDate }) => useQuery(['rewards-summary', startDate, endDate], () => fetchRewardsSummary({ startDate, endDate }));

export default {
  fetchRewardSettings,
  fetchRewardDefaults,
  updateRewardSettings,
  uploadNotifyFile,
  fetchRewardsList,
  fetchRewardsSummary,
  downloadRewardsList,
  downloadRewardsSummaryList,
  // queries
  useRewardSettingsQuery,
  useRewardDefaultsQuery,
  useRewardsListQuery,
  useRewardsSummaryQuery,

};
