/* eslint-disable */
<template>
  <section class="members-data">
    <div class="w-full mt-4 flex flex-wrap items-center justify-between">
      <div>
        <Spin :spinning="employersQuery && employersQuery.isLoading">
          <Select
            class="text-center min-w-[300px]"
            :value="employer_id"
            @change="handleSelectBusiness"
            allowClear
            placeholder="Select business"
          >
            <select-option v-for="buss in employersData" :key="buss._id" :value="buss._id"
              >{{ buss.community_name }} , {{ buss.name }}</select-option
            >
          </Select>
        </Spin>
      </div>
      <div class="flex flex-row space-x-4">
        <InputSearch
          class="rounded-md min-w-[320px]"
          placeholder="Search for email / member name"
          v-model:value="search"
          clear
          @change="handleSearch"
        />
        <div>
          <date-picker v-if="type !== 'all'" :picker="type" v-model:value="dateVal" />
        </div>
        <CustumSelect
          name="type"
          placeholder="select type "
          :data="dateData"
          :defaultData="type"
          :rules="{
            required: false,
          }"
          @setInput="setInput"
        />
      </div>
    </div>

    <QueryAlert :query="employeesQuery" />
    <QueryAlert :query="employersQuery" />

    <div class="mt-2 md:mt-3 lg:mt-4">
      <Table
        :data-source="tableFormatedData"
        :columns="columns"
        :loading="employeesQuery && (employeesQuery.isFetching || employeesQuery.isLoading)"
        :on-change="handleChange"
        :pagination="false"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'name'">
            <router-link
              :to="`/admin/members-management/${record.key}?name=${record.name}`"
              class="flex items-center gap-2"
            >
              <TypographyParagraph
                ellipsis
                :content="record.name"
                class="!text-primary underline hover:!text-primary-light whitespace-nowrap !mb-0"
              />
            </router-link>
          </template>

          <template v-if="column.key === 'last_trip'">
            <span v-if="record.last_trip" class="whitespace-nowrap">{{
              getDate(record.last_trip)
            }}</span>
            <span v-else class="w-full flex justify-center text-yellow-600">N/A</span>
          </template>

          <template v-if="column.key === 'joined'">
            <span v-if="record.joined" class="whitespace-nowrap">{{ getDate(record.joined) }}</span>
            <span v-else class="w-full flex justify-center text-yellow-600">N/A</span>
          </template>
          <template v-if="column.key === 'verf_status'">
            <span v-if="record.joined" class="whitespace-nowrap">{{ record?.email_verf ? 'Verified' : 'not verified' }} / {{ record?.active  ? 'Active' : 'Disabled' }}</span>
            <span v-else class="w-full flex justify-center text-yellow-600">N/A</span>
          </template>

          <template v-if="column.key === 'balance'">
            <span v-if="record?.total_credit" class="whitespace-nowrap text-center"
              >${{ record?.total_credit - record?.redeemed_balance }}</span
            >
            <span v-else class="whitespace-nowrap text-center">N/A</span>
          </template>
          <template v-if="column.key === 'redeemed_balance'">
            <span v-if="record.redeemed_balance" class="whitespace-nowrap text-center"
              >${{ record?.redeemed_balance ?? 0 }}</span
            >
            <span v-else class="whitespace-nowrap text-center">N/A</span>
          </template>
          <template v-if="column.key === 'total_credit'">
            <span v-if="record.total_credit" class="whitespace-nowrap text-center"
              >${{ record?.total_credit  }}</span
            >
            <span v-else class="whitespace-nowrap text-center">N/A</span>
          </template>
          <template v-if="column.key === 'trips'">
            <span v-if="record.trips" class="whitespace-nowrap text-center">{{
              record.trips
            }}</span>
            <span v-else class="whitespace-nowrap text-center">N/A</span>
          </template>

          <template v-if="column.key === 'action'">
            <div class="flex items-center gap-2">
              <!-- Delete  -->
              <Popconfirm
                title="Are you sure you want to delete?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="() => {
                  handleConfirm(record?.key, false, false, true);
                }"
              >
                <Button
                  type="primary"
                  size="small"
                  title="Delete"
                  class="btn-error rounded shadow flex items-center gap-2"
                >
                  <PhTrash weight="duotone" :size="16" />
                </Button>
              </Popconfirm>

              <!-- Decline  -->
              <Popconfirm
                title="Are you sure you want to decline?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="
                  () => {
                    handleConfirm(record.key, false, true);
                  }
                "
              >
                <Button
                  type="primary"
                  size="small"
                  title="Decline"
                  class="btn-warning rounded shadow flex items-center gap-2"
                  :loading="statusLoading"
                >
                  <PhX weight="duotone" :size="16" />
                </Button>
              </Popconfirm>

              <!-- Approve  -->
              <Popconfirm
                title="Are you sure you want to approve?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="
                  () => {
                    handleConfirm(record.key, true, false);
                  }
                "
                :loading="statusLoading"
              >
                <Button
                  type="primary"
                  size="small"
                  title="Approve"
                  class="rounded shadow flex items-center gap-2"
                >
                  <PhCheck weight="duotone" :size="16" />
                </Button>
              </Popconfirm>

              <!-- Disable -->
              <Popconfirm
                title="Are you sure you want to disable?"
                ok-text="Yes"
                cancel-text="No"
                :loading="statusLoading"
                @confirm="
                  () => {
                    handleConfirm(record.key, false, false);
                  }
                "
              >
                <Button
                  type="primary"
                  size="small"
                  title="Disable"
                  class="btn-error rounded shadow flex items-center gap-2"
                >
                  <PhProhibit weight="duotone" :size="16" />
                </Button>
              </Popconfirm>
            </div>
          </template>
        </template>
      </Table>

      <div v-if="employeesData && employeesData.meta" class="mt-6 flex items-center justify-end">
        <Pagination
          :current="employeesData?.meta?.current_page"
          :total="employeesData?.meta?.total"
          :page-size="employeesData?.meta?.per_page"
          :page-size-options="[10, 20, 50, 100, 500]"
          :show-size-changer="true"
          @change="handlePaginationChange"
        />
      </div>
    </div>

    <!-- query apis  -->
    <EmployeesQuery
      :page="page"
      :per_page="pageSize"
      :keyword="keyword"
      :employer_id="employer_id"
      :start_date="dates[0]"
      :end_date="dates[1]"
      :key="`${page}-${pageSize}-${keyword}-${employer_id}-${order_by}-${order_dir}-${dates[0]}-${dates[1]}`"
      :order_by="order_by"
      :order_direction="order_dir"
    />

    <EmployersQuery />
  </section>
</template>
<script>
/* eslint-disable */
import {
  Table,
  TypographyParagraph,
  Popconfirm,
  Button,
  InputSearch,
  Select,
  SelectOption,
  Pagination,
  Spin,
  message,
  DatePicker,
} from 'ant-design-vue';
import { PhCheck, PhX, PhProhibit, PhTrash } from 'phosphor-vue';
import dayjs from 'dayjs';
import CustumSelect from '@/components/shared/form/CustumSelect.vue';

import EmployeesQuery from '@/services/queries/admin/employees/EmployeesQuery.vue';
import EmployersQuery from '@/services/queries/admin/employers/EmployersQuery.vue';

import debounce from 'lodash.debounce';

import QueryAlert from '@/components/shared/QueryAlert.vue';
import handleResErrors from '@/utils/handleResErrors';
import employeesApi from '@/services/apis/admin/employees.api';

export default {
  components: {
    Table,
    Button,
    TypographyParagraph,
    Popconfirm,
    InputSearch,
    EmployeesQuery,
    PhCheck,
    PhX,
    PhProhibit,
    Select,
    SelectOption,
    PhTrash,
    QueryAlert,
    EmployersQuery,
    Pagination,
    Spin,
    CustumSelect,
    DatePicker,
  },
  data() {
    return {
      columns: [
        {
          title: 'Employee Name',
          key: 'name',
          dataIndex: 'name',
          backend_name: 'name',
          sorter: true,
          // sorter: (a, b) => {
          //   if (a.name > b.name) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: 'Email Address',
          dataIndex: 'email',
          key: 'email',
          backend_name: 'email',
          sorter: true,
          // sorter: (a, b) => {
          //   if (a.email > b.email) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: 'Total credit',
          dataIndex: 'total_credit',
          key: 'total_credit',
          backend_name: 'total_credit',

          // sorter: (a, b) => {
          //   if (a.balance > b.balance) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: 'Redeemed credit',
          dataIndex: 'redeemed_balance',
          key: 'redeemed_balance',
          backend_name: 'redeemed_balance',

          // sorter: (a, b) => {
          //   if (a.balance > b.balance) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },

        {
          title: 'Remaining credit (balance)',
          dataIndex: 'balance',
          key: 'balance',
          backend_name: 'wallet',

          // sorter: (a, b) => {
          //   if (a.balance > b.balance) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },

        

        {
          title: 'Number of logged trips',
          dataIndex: 'trips',
          key: 'trips',
          backend_name: 'number_of_trips',
          // sorter: (a, b) => {
          //   if (a.trips > b.trips) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: 'Last trip logged',
          dataIndex: 'last_trip',
          key: 'last_trip',
          backend_name: 'last_trip_date',
          // sorter: (a, b) => {
          //   const aDate = new Date(a.last_trip).getTime();
          //   const bDate = new Date(b.last_trip).getTime();
          //   return bDate - aDate;
          // },
        },
        {
          title: 'Joined on',
          dataIndex: 'joined',
          key: 'joined',
          sorter: true,
          backend_name: 'created_at',
          // sorter: (a, b) => {
          //   const aDate = new Date(a.joined).getTime();
          //   const bDate = new Date(b.joined).getTime();
          //   return bDate - aDate;
          // },
        },
        {
          title: 'Verification / Status',
          dataIndex: 'verf_status',
          key: 'verf_status',
          sorter: false,
          backend_name: '',
          // sorter: (a, b) => {
          //   const aDate = new Date(a.joined).getTime();
          //   const bDate = new Date(b.joined).getTime();
          //   return bDate - aDate;
          // },
        },
        {
          title: '',
          key: 'action',
          fixed: 'right',
        },
      ],

      tableData: [
        {
          key: '1',
          name: 'Daniel Martin',
          email: 'danielmartin@gmail.com',
          balance: 22,
          trips: 123,
          last_trip: 1681478087456,
          joined: 1681478087456,
        },

        ...Array(50)
          .fill('dd')
          .map((_, ix) => ({
            key: ix + 2,
            name: `John ${ix + 2}`,
            email: `johndoe${ix + 2}@gmail.com`,
            balance: this.getRandomInt(10, 500),
            trips: this.getRandomInt(10, 600),
            last_trip: this.getRandomInt(1420063200000, 1681478087456),
            joined: this.getRandomInt(1420063200000, 1681478087456),
          })),
      ],
      search: '',
      searchData: [],
      page: 1,
      pageSize: 10,
      name: '',
      type: 'all_time',
      dateVal: null,
      dateData: [
        // { id: 1, name: 'custom date' },
        { id: 'month', name: 'Monthly' },
        { id: 'year', name: 'Yearly' },
        { id: 'all_time', name: 'All time' },
        // { id: 'all', name: 'All Data' },
      ],
      email: '',
      modalMemb: null,
      delLoading: false,
      keyword: '',
      employer_id: null,
      statusLoading: false,
      params: {
        order_by: null,
        order_dir: null,
      },
    };
  },
  computed: {

    dates() {
      if (!this.dateVal) return [null, null];
      if (this.type === 'month') {
        const start = dayjs(this.dateVal).startOf('month').format('YYYY-MM-DD');
        const end = dayjs(this.dateVal).endOf('month').format('YYYY-MM-DD');
        return [start, end];
      }

      if (this.type === 'year') {
        const start = dayjs(this.dateVal).startOf('year').format('YYYY-MM-DD');
        const end = dayjs(this.dateVal).endOf('year').format('YYYY-MM-DD');
        return [start, end];
      }
      return [null, null];
    },
    employeesQuery() {
      return this.$store.state.adminEmployees.employeesQuery;
    },
    employeesData() {
      return this.$store.getters.employeesData;
    },

    employersQuery() {
      return this.$store.state.adminEmployers.employersQuery;
    },
    employersData() {
      return this.$store.getters.employersData?.data || [];
    },

    tableFormatedData() {
      if (!this.employeesData || !this.employeesData.data) return [];

      const data = this.employeesData.data.map((d, ix) => ({
        key: d._id || d.id || ix,
        name: d.name,
        email: d.email,
        balance: d.wallet?.balance || null,
        redeemed_balance: Number(d?.redeemed_balance)?.toFixed(2) || null,
        trips: d.number_of_trips || null,
        last_trip: d.last_trip_date,
        joined: d?.created_at,
        email_verf: d?.email_verified_at,
        active: d?.isactive,
        total_credit: Number(d?.total_benefits)?.toFixed(2)
      }));
      return data;
    },

    pagination() {
      console.log(this.pageSize);
      if (!this.employeesData) {
        return {
          defaultPageSize: this.pageSize,
          total: 0,
        };
      }
      return {
        defaultPageSize: this.employeesData?.meta?.per_page || this.pageSize,
        total: this.employeesData?.meta?.total || 0,
      };
    },
    pageSizeOptionsArr() {
      const dataLen = this.employeesData?.data?.length || 0;
      const pArr = [10];
      if (dataLen > 10) pArr.push(20);
      if (dataLen > 20) pArr.push(50);
      if (dataLen > 50) pArr.push(100);
      if (dataLen > 100) pArr.push(500);
      if (dataLen > 500) pArr.push(1000);
      if (dataLen > 1000) pArr.push(dataLen);

      return [...pArr];
    },
    order_by() {
      return this.params.order_by;
    },
    order_dir() {
      return this.params.order_dir;
    },
  },
  methods: {
    pageSizeOptionsArr() {
      const pArr = [10];
      pArr.push(20);
      pArr.push(50);
      pArr.push(100);
      pArr.push(500);
      pArr.push(1000);
      pArr.push(dataLen);

      return [...pArr];
    },
    // handlePaginationChange(pagination) {
    //   // , filters, sorter, data
    //   this.page = pagination.current;
    //   this.pageSize = pagination.pageSize;
    // },
    handlePaginationChange(page, pageSize) {
      // , filters, sorter, data
      this.page = page;
      this.pageSize = pageSize;
    },
    getRandomInt(min, max) {
      const xmin = Math.ceil(min);
      const xmax = Math.floor(max);
      return Math.floor(Math.random() * (xmax - xmin + 1)) + min;
    },
    getDate(d) {
      // February 18, 2022, 4:43 am
      return dayjs(d).format('MMM DD, YYYY');
    },
    handleSearch(e) {
      this.debounceSearch(e.target.value, this);
    },
    debounceSearch: debounce((search, app) => {
      // eslint-disable-next-line no-param-reassign
      app.page = 1;
      // eslint-disable-next-line no-param-reassign
      app.keyword = search;
    }, 400),

    handleSelectBusiness(val) {
      this.page = 1;
      this.employer_id = val;
    },
    async handleConfirm(id, isApprove, isDecline, isDelete) {
      this.statusLoading = true;
      try {
        let res;
        if (isDelete) {
          res = await employeesApi.deleteEmployee(id);
        } else {
          res = await employeesApi.updateEmployeeStatus(id, isApprove, isDecline);
        }
        if (res) {
          message.success('Status updated successfully');
        }
        if (this.employeesQuery) {
          await this.employeesQuery.refetch();
        }
      } catch (error) {
        message.error(handleResErrors(error), 10);
      } finally {
        this.statusLoading = false;
      }
    },
    handleChange(pagination, filters, sorter) {
      console.log(sorter);
      // eslint-disable-next-line no-prototype-builtins
      if (sorter.hasOwnProperty('column')) {
        this.params.order_by = sorter.column.backend_name;
        // eslint-disable-next-line eqeqeq
        if (sorter.order == 'ascend') this.params.order_dir = 'ASC';
        else this.params.order_dir = 'DESC';
      }
      // const par = {
      //   ...this.params,
      //   page: pagination.current,
      //   pageSize: pagination.pageSize,
      // };
      // this.employeesQuery.refetch(par);
    },
    setInput(val) {
      if (val.type && val.type.value) {
        this.type = val.type.value;
      }
      if (val.transactionType && val.transactionType.value) {
        this.transactionType = val.transactionType.value;
      }
    },
  },
};
</script>
