<template>
  <div class="w-full p-2" >
    <div  v-if='chartTypeComp == "Initial"'>
      <Button
        type="primary"
        size="large"
        class="btn-gradient"
        @click="() => connectedCharts = ~connectedCharts"
      >
        <span v-if="connectedCharts">Disconnect Charts</span>
        <span v-else>Connect Charts</span>
      </Button>
    </div>
    <div class='chart-container'>
      <div class="mt-6 grid grid-cols-1 xl:grid-cols-2 gap-10 items-end">

          <div  v-if='chartTypeComp == "Initial"'>
          <MainChart :query="initialQuery" :data="initialData" @setDate="setInitialDate" @updateDefaultType='updateDefaultType'  defaultType="Initial" />
          </div>
          <div  v-else>
            <MainChart
            :query="filiterQueryCompare"
            :data="filiterCompareData"
            :defaultYear="defaultFilterYear"
            @setDate="setFiliterDateCompare"
            :startMonth="startMonth"
            :key="defaultFilterYear + '-' + startMonth"
            :showInitial='true'
            @updateDefaultType='updateDefaultType'
          />
          </div>
          <MainChart
            :query="filiterQuery"
            :data="filiterData"
            :defaultYear="defaultFilterYear"
            @setDate="setFiliterDate"
            :startMonth="startMonth"
            :key="defaultFilterYear + '-' + startMonth"
          />

      </div>
    </div>
    <ReportChart
      queryname="modeShareBehaviorStatsQuery"
      gettername="modeShareBehaviorStatsData"
      :commutersData="commutersData"
      :year="reportYear"
      title="Commute Behavior Report"
      filter="Trips"
      @setDate="setReportDate"
    />

    <!-- api queries  -->
    <StatsModeShareInitialQuery
      :from="initialDate[0]"
      :to="initialDate[1]"
      :key="initialDate[0]+initialDate[1]"
    />
    <StatsModeMonthQuery
      v-if="filiterDate"
      :from="filiterDate[0]"
      :to="filiterDate[1]"
      :key="filiterDate[0]+filiterDate[1]"
    />
    <StatsModeMonthQueryCompare
      v-if="initialDateCompare"
      :from="initialDateCompare[0]"
      :to="initialDateCompare[1]"
      :key="initialDateCompare[0]+initialDateCompare[1]"
      :compare='true'
    />

    <StatsModeMonthQuery
      v-if="filiterDate"
      :from="filiterDate[0]"
      :to="filiterDate[1]"
      :key="filiterDate[0]+filiterDate[1]"
    />
    <StatsModeShareBehaviorQuery
      v-if="reportDate"
      :from="reportDate[0]"
      :to="reportDate[1]"
      :key="reportDate[0]+reportDate[1]"
    />

    <StatsModeShareCommutersQuery
      v-if="reportDate"
      :from="reportDate[0]"
      :to="reportDate[1]"
      :key="reportDate[0]+reportDate[1]"
    />
  </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line import/no-duplicates
import StatsModeShareInitialQuery from '@/services/queries/stats/StatsModeShareInitialQuery.vue';
// eslint-disable-next-line import/no-duplicates
import StatsModeMonthQueryCompare from '@/services/queries/stats/StatsModeMonthQuery.vue';
import StatsModeShareBehaviorQuery from '@/services/queries/stats/StatsModeShareBehaviorQuery.vue';
// eslint-disable-next-line import/no-duplicates
import StatsModeMonthQuery from '@/services/queries/stats/StatsModeMonthQuery.vue';
import StatsModeShareCommutersQuery from '@/services/queries/stats/StatsModeShareCommutersQuery.vue';

import { Button, Skeleton, notification } from 'ant-design-vue';
import dayjs from 'dayjs';
import ReportChart from './charts/ReportChart.vue';

import MainChart from './charts/MainChart.vue';

import getActiveMonth from '../shared/getActiveMonth';

export default {
  components: {
    ReportChart,
    Button,
    StatsModeShareInitialQuery,
    StatsModeShareBehaviorQuery,
    StatsModeMonthQuery,
    MainChart,
    StatsModeShareCommutersQuery,
    StatsModeMonthQueryCompare,
    Skeleton,
    notification
  },

  data() {
    return {
      reportYear: dayjs(),
      defaultFilterYear: dayjs(),
      reportDate: null,
      filiterDate: null,
      initialDate: [null, null],
      startMonth: 1,
      connectedCharts: false,
      checkLoading: false,
      filterDataChanged: false,
      chartType: 'Initial',
      initialDateCompare: [null,null],
      loading:false,
      componentKey:0,
    };
  },

  created() {
    const currYear = dayjs().year();
    const yearDate = [`${currYear}-01-01`, `${currYear}-12-31`];

    if (!this.reportDate) {
      this.reportDate = yearDate;
    }
    if (!this.filiterDate) {
      this.filiterDate = yearDate;
    }
    this.initialDateCompare = yearDate
  },
  errorCaptured(){
    this.openNotification()
    setTimeout(()=>{
      window.location.reload()
    },2000)

  },
  computed: {
    initialQuery() {
      return this.$store.state.stats.modeShareInitialStatsQuery;
    },
    initialData() {
      return this.$store.getters.modeShareInitialStatsData;
    },
    filiterQuery() {
      return this.$store.state.stats.modeShareMonthStatsQuery;
    },
    filiterQueryCompare() {
      return this.$store.state.stats.modeShareMonthStatsCompareQuery;
    },
    filiterData() {
      return this.$store.getters.modeShareMonthStatsData;
    },
    filiterCompareData() {
      return this.$store.getters.modeShareMonthStatsCompareData;
    },
    reportData() {
      return this.$store.getters.modeShareBehaviorStatsData;
    },
    commutersData() {
      return this.$store.getters.modeShareCommutersData;
    },
    chartTypeComp() {
      return this.chartType;
    },
    isLoading() {
      return this.loading
    }
  },
  watch: {
    reportData: {
      async handler(val) {
        if (!this.checkLoading && val && !this.filterDataChanged) {
          this.checkLoading = true;
          const { firstMonth, year } = await getActiveMonth(val, this.startMonth);

          if (firstMonth) {
            this.startMonth = firstMonth;
            this.filterDataChanged = true;
          }
          if (year && year !== dayjs(this.defaultFilterYear).year()) {
            const endMon = dayjs(`${year}-${firstMonth ? firstMonth + 1 : 1}-01`)
              .endOf('month')
              .format('YYYY-MM-DD');
            this.setFiliterDate([`${year}-01-01`, endMon]);
            this.defaultFilterYear = dayjs().year(year);

            // change behavior report chart default year
            this.reportYear = dayjs().year(year);
            const currYear = dayjs()
              .year(year)
              .year();
            this.reportDate = [`${currYear}-01-01`, `${currYear}-12-31`];
          }
          this.checkLoading = false;
        }
      },
      deep: true,
    },
    connectedCharts(val) {
      if (val) {
        this.initialDate = this.filiterDate;
        this.initialDateCompare = this.filiterDate;
      } else {
        this.initialDate = [null, null];
      }
    },
    // filiterData(val) {
    //   if (val && val.no_commuters === 0) {
    //   }
    // },
  },
  methods: {
    setReportDate(y) {
      this.reportYear = y;
      const currYear = dayjs(y).year();

      const reportDate = [`${currYear}-01-01`, `${currYear}-12-31`];
      this.reportDate = reportDate;
    },
    setFiliterDate(dates) {
      this.filiterDate = dates;
      if (this.connectedCharts) {
        this.initialDate = dates;
      }
    },
    setFiliterDateCompare(dates) {
      if(this.chartType == 'Initial'){
        this.loading = true;
        setTimeout(()=>{
          this.loading = false;
        },1000)

        this.initialDateCompare = ['2023-01-01', '2023-01-31'];
        return
      }
      this.initialDateCompare = dates;
      if (this.connectedCharts) {
        this.filiterDate = dates;
      }
    },
    setInitialDate(dates) {

      this.initialDate = dates;
    },
    updateDefaultType(type) {
      this.loading = true;
      setTimeout(()=>{
        this.loading = false;
      },1000)
      if(type != 'Initial'){
        this.initialDateCompare = ['2023-01-01', '2023-01-31'];
        this.chartType = type;
        return
      }else{
        // this.loading = true;
        this.initialDate = ['2010-01-01', '2110-01-01'];
      }
      this.chartType = type;
    },
    openNotification(){
      notification.error({
        message: 'Something went wrong',
        description: 'Something went wrong while filtering, the page will be reloaded now !',
        duration: 6,
      });
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },

};
</script>
