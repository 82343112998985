<template>
  <div style='overflow-x: hidden;max-width: 100%'>
    <HeaderView>
      <div class="container" >
        <div class="card-container">
          <div class="w-full main-card px-3 md:px-4 lg:px-5 py-4 lg:py-5 xl:py-7">
            <div>
              <h2>Description of Service</h2>
              <p>Our Service provides businesses with a commuting program dashboard that offers monthly or yearly subscription-based access to various services designed to facilitate employees’ commutes. These services may include route planning, transportation options, reporting, reward system, scores, and other related features.</p>
              <h2>User Obligations</h2>
              <h3>2.1 Registration and Account</h3>
              <p>In order to use the Service, you must create an account and provide accurate and complete information. You are solely responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</p>
              <p>Usage of Commutrics mobile app may result in asking the app to track your rides to have full access to the service benefits. By accepting, you are giving us your consent to collect and track your rides.</p>
              <h3>2.2 Compliance with Laws</h3>
              <p>You agree to comply with all applicable laws, rules, and regulations while using the Service. You are solely responsible for obtaining any necessary permissions or authorizations required to access and use the Service in your jurisdiction.</p>
              <h3>2.3 Prohibited Activities</h3>
              <p>You agree not to engage in any of the following activities while using the Service:</p>
              <ul>
                <li>Violating any applicable laws or regulations.</li>
                <li>Infringing upon the intellectual property rights or other proprietary rights of any party.</li>
                <li>Uploading, transmitting, or distributing any harmful, unlawful, or objectionable content.</li>
                <li>Interfering with or disrupting the Service or the servers or networks connected to the Service.</li>
                <li>Attempting to gain unauthorized access to any portion of the Service or any other accounts, systems, or networks.</li>
              </ul>
              <h2>Subscription and Payment</h2>
              <h3>3.1 Subscription Plans</h3>
              <p>We offer monthly and yearly subscription plans for businesses to access and use the Service. The details, features, and pricing of each subscription plan are provided on our website. By subscribing to a plan, you agree to pay the applicable fees as described.</p>
              <h3>3.2 Payment and Billing</h3>
              <p>Payment for the subscription plans is processed through the designated payment tool that we do not have full control over. You authorize us to charge your chosen payment method for the applicable fees on a recurring basis according to your selected subscription plan. You are responsible for keeping your payment information up to date.</p>
              <p>Subscription invoices are generated at the beginning of each month.</p>
              <h3>3.3 Cancellation and Refunds</h3>
              <p>You may cancel your subscription at any time. However, no refunds will be provided for any unused portion of a subscription plan. Upon cancellation, your access to the Service will be terminated at the end of the current billing cycle.</p>
              <h2>Intellectual Property</h2>
              <p>The Service and all related content, including but not limited to software, text, graphics, logos, and images, are owned by or licensed to us and are protected by intellectual property laws. You may not reproduce, modify, distribute, or otherwise use any of the content without our prior written consent.</p>
              <h2>Limitation of Liability</h2>
              <p>To the maximum extent permitted by law, we shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in connection with the use or inability to use the Service, even if we have been advised of the possibility of such damages.</p>
              <h2>Indemnification</h2>
              <p>You agree to indemnify, defend, and hold us harmless from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in connection with your use of the Service or any violation of these Terms.</p>
              <h2>Geographic Restrictions</h2>
              <p>Commutrics is based in the state of Colorado in the United States. We make no claims that the dashboard or any of its content is accessible or appropriate outside of the United States. Access to the dashboard may not be legal for certain persons or in certain countries. If you access the dashboard from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.</p>
              <h2>Modifications and Termination</h2>
              <p>We reserve the right to modify or terminate the Service or these Terms at any time without prior notice. You are responsible for regularly reviewing these Terms. Your continued use of the Service after any modifications to the Terms shall constitute your acceptance of the modified Terms.</p>
              <h2>Governing Law and Jurisdiction</h2>
              <p>These Terms shall be governed by and construed in accordance with the laws of Denver, Colorado. Any disputes arising out of or relating to these Terms or the Service shall be subject to the exclusive jurisdiction of the courts located in Denver.</p>
              <h2>Arbitration</h2>
              <p>The Company reserves the right to enforce arbitration for any disputes arising from the use of these Terms of Use. This includes disputes related to the interpretation, violation, invalidity, non-performance, or termination of the Terms. The arbitration process will be final and binding, following the Rules of Arbitration of the American Arbitration Association and applying Colorado law.</p>
              <h2>Disclaimer of Warranties</h2>
              <p>You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or dashboard will be free of viruses or other destructive code. It is your responsibility to implement necessary procedures and safeguards to ensure antivirus protection and accuracy of data input and output. You should also maintain an external backup system to reconstruct any lost data.</p>
              <p>We will not be held liable for any loss or damage resulting from distributed denial-of-service attacks, viruses, or other technologically harmful materials that may infect your computer equipment, programs, data, or other proprietary materials due to use of the dashboard or any services or items obtained through it. This includes the downloading of any material posted on the dashboard or linked applications.</p>
              <p>We do not make any representations or warranties that the dashboard will function at all times and in all locations, or that notifications will be received in a timely manner. Additionally, we do not guarantee that your vehicle and equipment will remain undamaged while using the dashboard.</p>
              <h2>Agreement</h2>
              <p>The Terms of Use and Privacy Policy serve as the exclusive and comprehensive agreement between you and The Pick Path Group, LLC, specifically regarding the dashboard. They override any previous or concurrent understandings, agreements, representations, and warranties, whether written or verbal, concerning the Commutrics Service.</p>
              <h2>Requests &amp; Feedback</h2>
              <p>Commutrics is operated by The Pick Path Group, LLC. If you have any requests, feedback, comments, and other communications, please contact us through admin@commutrics.co</p>
            </div>
          </div>
        </div>
      </div>
    </HeaderView>

  </div>
  <div style='width: 100%;height: 80px;line-height: 160px;text-align: center'>
    Copyright © the Pick Path Group, LLC - 2023 (All Rights Reserved)
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderView from '@/views/HeaderView.vue';

export default {
  components: {
    HeaderView,
  },
};
</script>
<style scoped>
.main-card{
  margin:10px 0;
  border-color: transparent;  background-color: rgba(255, 255, 255, 0.5);
}
</style>

<!--<script setup>-->
<!--import { ref, onBeforeMount } from 'vue';-->
<!--import HeaderView from '@/views/HeaderView.vue';-->
<!--import apiAxios from '@/services/apiAxios';-->
<!--import { Skeleton } from 'ant-design-vue';-->

<!--const content = ref('');-->
<!--const loading = ref(true);-->
<!--const getAgreementData = async () => {-->
<!--  const { data } = await apiAxios.get('/agreements/terms-and-conditions');-->
<!--  content.value = data?.data?.content;-->
<!--  loading.value = false;-->
<!--};-->
<!--onBeforeMount(() => {-->
<!--  getAgreementData();-->
<!--});-->
<!--</script>-->

<!--<template>-->
<!--  <div style='overflow-x: hidden;max-width: 100%'>-->

<!--          <HeaderView>-->
<!--            <div class="container" >-->
<!--              <div class="card-container">-->
<!--                <div class="w-full main-card px-3 md:px-4 lg:px-5 py-4 lg:py-5 xl:py-7">-->
<!--                  <div v-if='!loading' v-html='content'>-->
<!--                  </div>-->
<!--                  <Skeleton v-else-if='loading'></Skeleton>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </HeaderView>-->

<!--  </div>-->
<!--  <div style='width: 100%;height: 80px;line-height: 160px;text-align: center'>-->
<!--    Copyright © the Pick Path Group, LLC - 2024 (All Rights Reserved)-->
<!--  </div>-->
<!--</template>-->

<!--<style scoped>-->
<!--.main-card{-->
<!--  margin:10px 0;-->
<!--  border-color: transparent;  background-color: rgba(255, 255, 255, 0.5);-->
<!--}-->
<!--</style>-->
