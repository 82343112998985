<template>
  <div class="transportation-page py-4 mt-4">
    <div class="container">
      <LineLoader v-if="settingsQuery?.isFetching" />
      <!-- <CTAsection /> -->
      <!-- <div class="mt-6 lg:mt-8"></div> -->
      <div
        v-if="!settingsQuery || (settingsQuery.isLoading && !settingsQuery.isError)"
        class="flex flex-col gap-4 main-card px-3 md:px-4 lg:px-5 py-4 lg:py-5 xl:py-7"
      >
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </div>

      <Tabs
        v-if="settingsQuery && settingsQuery.data && !settingsQuery.isLoading "
        v-model:activeKey="activeKey"
        @edit="onEdit"
      >
        <TabPane v-for="tab in mainTabs" :tab="tab.title" :key="tab.key" :closable="false">
          <div v-if="tab.key === '1'">
            <RewardSelect v-if="settingsQuery && settingsQuery.data && !settingsQuery.isLoading " />
          </div>

          <div
            v-if="tab.key === '2'"
            class="w-full main-card px-3 md:px-4 lg:px-5 py-4 lg:py-5 xl:py-7"
          >
            <p>NEW PROGRAM (will be integrated fro backend)</p>
          </div>
        </TabPane>
      </Tabs>
      <LineLoader v-if="settingsQuery?.isFetching" />
    </div>

    <!-- api  -->
    <RewardDefaultsQuery />
    <RewardSettingsQuery />
  </div>
</template>
<script>
// import CTAsection from './CTAsection.vue';
import RewardDefaultsQuery from '@/services/queries/reward/RewardDefaultsQuery.vue';
import RewardSettingsQuery from '@/services/queries/reward/RewardSettingsQuery.vue';
import { Skeleton, Tabs, TabPane } from 'ant-design-vue';
import LineLoader from '@/components/shared/LineLoader.vue';
import RewardSelect from './RewardSelect.vue';

export default {
  components: {
    // CTAsection,
    RewardSelect,
    RewardDefaultsQuery,
    RewardSettingsQuery,
    Skeleton,

    LineLoader,
    Tabs,
    TabPane,
  },
  data() {
    return {
      activeKey: '1',
      mainTabs: [
        {
          title: 'Reward System',
          key: '1',
        },
      ],
    };
  },
  computed: {
    settingsQuery() {
      return this.$store.state.reward.rewardSettingsQuery;
    },
  },
  methods: {
    onEdit() {
      // console.log('onEdit', targetKey, action);
      if (this.mainTabs.length >= 2) {
        return;
      }
      this.mainTabs.push({
        title: 'Program 2 title',
        key: '2',
      });
      this.activeKey = '2';
    },
  },
};
</script>
