import { useQuery } from 'vue-query';
import apiAxios from '../../apiAdminAxios';

const fetchEmployers = async ({ page, per_page, keyword }) => {
  let URL = `?page=${page || 1}`;

  if (per_page) {
    URL += `&per_page=${per_page}`;
  }
  if (keyword) {
    URL += `&keyword=${keyword}`;
  }

  const { data } = await apiAxios.get(`/admin/employers${URL}`);
  return data;
};

const updateEmployer = async ({ id, status }) => {
  const res = await apiAxios.put(`/admin/employers/${id}`, { status });
  return res;
};

// queries
const useEmployersQuery = ({ page, per_page, keyword }) => useQuery(['admin/employers', page, per_page, keyword], () => fetchEmployers({ page, per_page, keyword }), { refetchOnMount: false });

export default {
  fetchEmployers,
  updateEmployer,
  // queries
  useEmployersQuery,

};
